import { roundNonZero } from "./components/utils"
import { CartProductInfo } from "./types"

export function caclulateSavings(productValue: number, averageValue: number, productVolume: number, multipleCount: number) {
    const valueSavings = Math.abs(productValue - averageValue)
    let productSavings = valueSavings * productVolume * multipleCount
    if (productValue > averageValue) productSavings *= -1
    return { valueSavings, productSavings }
}

export function getSavingsString(product: CartProductInfo) {
    const { multipleCount, category_volume, valueSavings, count, productSavings } = product
    const productSign = productSavings > 0 ? "" : "-"
    let savingsString = ''
    if (multipleCount > 1) {
        savingsString = count > 1 ? `(${productSign}R${roundNonZero(valueSavings)}*${category_volume}*${multipleCount})*${count} = ${productSign}R${Math.abs(productSavings*count).toFixed(2)}` :
            `${productSign}R${roundNonZero(valueSavings)}*${category_volume}*${multipleCount} = ${productSign}R${Math.abs(productSavings*count).toFixed(2)}`
    } else {
        savingsString = count > 1 ? `${productSign}R${roundNonZero(valueSavings)}*${category_volume}*${count}= ${productSign}R${Math.abs(productSavings*count).toFixed(2)}`
            : `${productSign}R${roundNonZero(valueSavings)}*${category_volume} = ${productSign}R${Math.abs(productSavings).toFixed(2)}`
    }
    return savingsString
}