import { Icon, Overlay2 } from "@blueprintjs/core";
import { useState } from "react";
import "./BasketOverlay.css";
import { CartProductInfo, SelectionState } from "../types";
import { useCartContext } from "../hooks/useCartContext";
import { CloseIcon } from "@chakra-ui/icons";
import { useSelectionContext } from "../hooks";
import { useNavigate } from "react-router-dom";
import { filterTitle, getProductPrice, getProductVolume } from "./utils";
import { imageWidths, storeIcon } from "./resources";
import { Badge } from "@chakra-ui/react";

export function BasketOverlay({ product }: { product: CartProductInfo }) {
  const [isOpen, setIsOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const navigate = useNavigate();
  const { cartState, setCartState } = useCartContext();
  const { selectionState, setSelectionState } = useSelectionContext();
  const [count, setCount] = useState(product.count);
  const productTitle = filterTitle(product).split(' x')[0]
  const productVolume = getProductVolume(product)
  const productPrice = getProductPrice(product, count)

  function handleItemRemove() {
    const newCartState = { ...cartState };
    delete newCartState[product.multipleId];
    setCartState(newCartState);
    handleCloseClick()
  }

  function handleCountClick(x: number) {
    const newCount = count + x <= 1 ? 1 : count + x;
    if (newCount !== product.count) setHasChanged(true);
    else setHasChanged(false);
    setCount(newCount);
  }

  function handleAlternativeClick() {
    const searchState = selectionState[product.multipleId];
    setSelectionState((prev: SelectionState) => ({
      ...prev,
      current: searchState,
    }));
    return navigate("/shop");
  }

  function handleOpenClick() {
    setIsOpen(true);
    setIsClosed(false);
  }

  function handleCloseClick() {
    setIsOpen(false);
    setIsClosed(true)
    setCount(product.count);
    setHasChanged(false);
  }

  function handleApply() {
    const newCartState = { ...cartState };
    newCartState[product.multipleId].count = count;
    setCartState(newCartState);
    handleCloseClick()
  }

  return (
    <>
      <Icon
        icon="edit"
        color={"#CCCCCC"}
        className="edit_button"
        onClick={handleOpenClick}
      />
      <Overlay2
        isOpen={isOpen}
        // onClosed={() => setIsClosed(true)}
        className={isClosed ? "" : "card_modal_overlay"}
        hasBackdrop={true}
      >
        <div className="cart_overlay">
          <div className="overlay_content">
            <div className="overlay_content_header">
              <span>
                {product.isValueChoice && <Badge colorScheme="blue" fontSize={'8px'} variant="subtle" borderRadius={'16px'}>
                  VALUE
                </Badge>}</span>
              <CloseIcon
                className="overlay_close"
                onClick={handleCloseClick}
                color={"#CCCCCC"}
                boxSize={3}
              />
            </div>
            <div className="overlay_product">
              <div className="overlay_product_left">
                <img src={storeIcon[product.store]} width={50} alt={"check"} />
                {productTitle.length < 30 ? (
                  <text className="card_title" style={{ textAlign: "right" }}>{productTitle} <text className="card_title_highlight">{product.multipleCount > 1 ? `[X${product.multipleCount}]` : ''}</text></text>
                ) : (
                  <text className="card_title" style={{ fontSize: "14px", textAlign: "right" }}>{productTitle} <text className="card_title_highlight">{product.multipleCount > 1 ? `[X${product.multipleCount}]` : ''}</text></text>
                )}
                <text className="card_brand">{product.brand}</text>
                <text className="card_volume">
                  {productVolume}
                </text>
                <text className="card_price">
                  <Icon icon="tag" color="#666666;" size={10} />R
                  {productPrice}
                </text>
                <span
                  className="card_unit_count_container"
                  style={{ marginTop: "8px", marginLeft: "8px" }}
                >
                  <span className="unit_icon">
                    <Icon
                      icon="minus"
                      color="white"
                      size={14}
                      onClick={() => handleCountClick(-1)}
                    />
                  </span>
                  <div className="card_unit_count">{count}</div>
                  <span className="unit_icon">
                    <Icon
                      icon="plus"
                      color="white"
                      size={14}
                      onClick={() => handleCountClick(1)}
                    />
                  </span>
                </span>
              </div>
              <div className="overlay_product_right">
                <img src={product.image_url} alt={product.title} width={imageWidths[product.store] + 50} />
              </div>
            </div>
            <div className="overlay_product_buttons">
              <button
                className="overlay_find_button"
                onClick={handleAlternativeClick}
              >
                Find alternative
              </button>
              {hasChanged ? (
                <button className="overlay_apply_button" onClick={handleApply}>
                  Apply
                </button>
              ) : (
                <button
                  className="overlay_remove_button"
                  onClick={handleItemRemove}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        </div>
      </Overlay2>
    </>
  );
}
