import { extendTheme } from '@chakra-ui/react';
import { SelectionState } from './types';

export const BACKEND_URL = 'https://pickandprice-backend-584129575375.us-west1.run.app'

export const MEAT_CATEGORIES = ["Beef", "Chicken", "Lamb", "Mutton", "Ostrich", "Pork", "Turkey", "Veal" ,"Venison"]
export const VALUE_UNITS = ["g", "ml"]

export const initialSelectionState: SelectionState = {
  current: {
    id: null,
    category: '',
    subCategories: {},
    brands: {},
    minVolume: -Infinity,
    maxVolume: Infinity,
    store: {
      woolworths: false,
      checkers: false,
    },
  },
};

export const _INITIAL_RANKING_DETAILS = {
  bestValueProductId: '',
  bestValueProductAmount: Infinity,
  averageValueAmount: 0
}

export enum VolumeUnits {
  ml = 'ml',
  l = 'l',
  kg = 'kg',
  g = 'g',
  L = 'l',
  cm = 'cm',
  pack = 'pack',
  pk = 'pk',
  single = 'single',
  half = 'half',
  quarter = 'quarter',
  bag = 'bag',
  tray = 'tray',
  bunch = 'bunch',
  punnet = 'punnet',
  piece = 'piece',
}

export const customTheme = extendTheme({
  colors: {
    custom: {
      50: '#FFFFFF', // White
      100: '#F9FAFB', // Off-white
      200: '#F4F5F7', // Light gray
      300: '#E5E7EB', // Gray
      400: '#D1D5DB', // Dark gray
      500: '#9CA3AF', // Neutral
      600: '#6B7280', // Dark neutral
      700: '#4B5563', // Darker neutral
      800: '#374151', // Darker gray
      900: '#1F2937', // Black
    },
  },
  // Optionally, define other custom theme properties such as fonts, sizes, etc.
});