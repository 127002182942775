import { useCartContext } from '../hooks/useCartContext';
import './Basket.css';
import { BasketOverlay } from '../components/BasketOverlay';
import { storeListIcon } from '../components/resources';
import { Badge } from '@chakra-ui/react';
import { Icon } from '@blueprintjs/core';
import { useMemo, useState } from 'react';
import { filterTitle, roundNonZero } from '../components/utils';
import { CartProductInfo } from '../types';
import { getSavingsString } from '../utils';

export function Basket() {
  const { cartState } = useCartContext();
  const [showSavings, setShowSavings] = useState<boolean>(false)
  const price = useMemo(() => {
    let price = 0
    for (const productId in cartState) {
      price += cartState[productId].price * cartState[productId].count * cartState[productId].multipleCount
    }
    return price
  }
    , [cartState])

  const { savings, valueSelected } = useMemo(() => {
    let savings = 0
    let valueSelected = 0
    for (const product of Object.values(cartState)) {
      if (product.isValueChoice) valueSelected++
      savings += (product.productSavings * product.count)
    }
    return { savings, valueSelected }
  }, [cartState])

  const sortedProductIds = useMemo(() => {
    const ids = Object.values(cartState).sort((a: CartProductInfo, b: CartProductInfo) => {
      const aSavings = a.productSavings * a.count
      const bSavings = b.productSavings * b.count
      return bSavings - aSavings
    })
    return ids
  }, [cartState])

  const isEmpty = Object.keys(cartState).length === 0;
  if (isEmpty)
    return (
      <div className="empty_container">
        <div className="empty_content">
          <Icon icon="shopping-cart" color="#AAAAAA" size={25} />
          <text>Your cart is empty</text>
        </div>
      </div>
    );

  const productIds = Object.keys(cartState)
  const isPositive = savings > 0

  return (
    <div className="page_container">
      <div className="cart_container">
        <div className="cart_header">
          <div className="cart_header_row">
            <span className="cart_header_span">
              <text className="cart_header_text">Items</text>
              <Badge fontSize={'16px'} borderRadius={'16px'}>{Object.keys(cartState).length}</Badge>
            </span>
            <span className="cart_header_span">
              <text className="cart_header_text">Total </text>
              <Badge fontSize={'16px'} borderRadius={'16px'}>R{price.toFixed(2)}</Badge>
            </span>
          </div>
        </div>
        <div className="cart_list">
          {Object.values(cartState).map((product, index) => {
            const { price, count, multipleCount, store, title, image_url: imageUrl } = product
            const productPrice = (Number(price * multipleCount) * count).toFixed(2)
            return (<div className="cart_row">
              <div className="cart_item">
                <text className="cart_item_count">{index + 1}. </text>
                <img
                  src={storeListIcon[store]}
                  alt={title}
                  width={10}
                />
                <img src={imageUrl} alt={title} width={25} />
                <text className="cart_item_count">{count}</text>
                <text className="cart_item_text">
                  {filterTitle(product)}
                </text>
              </div>
              <div className="cart_edit">
                <text className="cart_item_price">
                  R{productPrice}
                </text>
                {<BasketOverlay product={product} />}
              </div>
            </div>)
          })}
        </div>
        <div className="cart_savings">
          <div className="cart_savings_container">
            <span className='cart_savings_span_dropdown' style={{ boxShadow: 'none' }}>
              <span className='cart_savings_text_price'>
                <text className="cart_savings_text">Savings</text>
                <Badge colorScheme={isPositive ? "green" : "red"} fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
                  R{savings.toFixed(2)}
                </Badge>
              </span>
              <Icon icon={showSavings ? 'chevron-down' : 'chevron-right'} color="#AAAAAA" onClick={() => setShowSavings(prev => !prev)} />
            </span>
            {showSavings && <div className='cart_savings_dropdown'>
              <div className='cart_savings_dropdown_left'>
                {sortedProductIds.map((product) => {
                  const index = productIds.indexOf(product.multipleId)
                  return (
                    <text className='cart_savings_list_text cart_savings_title'>{index + 1}. {product.category}: {product.sub_category}</text>)
                })}
              </div>
              <div className='cart_savings_dropdown_right'>
                {sortedProductIds.map((product) => {
                  const { productSavings } = product
                  const isGreen = productSavings > 0
                  const savingsString = getSavingsString(product)
                  return (<text className='cart_savings_list_text' style={{ color: isGreen ? '#3bb56c' : '#b53b3b' }}>{savingsString}</text>)
                })}
              </div>
            </div>}
          </div>
          <span className="cart_savings_span">
            <text className="cart_savings_text">Value items selected</text>
            <Badge colorScheme='blue' fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
              {valueSelected}
            </Badge>
            /
            <Badge colorScheme='blue' fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
              {Object.keys(cartState).length}
            </Badge>
          </span>
        </div>
      </div>
    </div>
  );
}
